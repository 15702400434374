import React, { useEffect } from 'react';
import slide1 from '../images/slide1'; // Update with the correct paths
import slide3 from '../images/slide3'; // Update with the correct paths
import slide4 from '../images/slide4'; // Update with the correct paths
import slide5 from '../images/slide5'; // Update with the correct paths
import slide6 from '../images/slide6'; // Update with the correct paths
import slide7 from '../images/slide7'; // Update with the correct paths
import slide8 from '../images/slide8'; // Update with the correct paths
import slide9 from '../images/slide9'; // Update with the correct paths
import slide10 from '../images/slide10'; // Update with the correct paths
import slide11 from '../images/slide11'; // Update with the correct paths
import 'bootstrap/dist/css/bootstrap.min.css';
import './carousel.scss';

const Carousel = () => {
  useEffect(() => {
    const bootstrap = require('bootstrap');
    const myCarousel = document.querySelector('#carouselExampleControls');
    new bootstrap.Carousel(myCarousel, {
      interval: 20000,
    });
  }, []);

  return (
<div className="container-fluid">
  <div className='row'>
  <div id="carouselExampleControls" className="carousel slide carousel-container" data-bs-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img className="d-block mx-auto w-100" src={slide1} alt="First slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide3} alt="Second slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide4} alt="Third slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide5} alt="Third slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide6} alt="Third slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide7} alt="Third slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide8} alt="Third slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide9} alt="Third slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto w-100" src={slide10} alt="Third slide" />
      </div>
      <div className="carousel-item">
        <img className="d-block mx-auto" src={slide11} alt="Third slide" />
      </div>
    </div>
    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </a>
  </div>
</div>
</div>

  );
};

export default Carousel;
